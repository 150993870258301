import { Regions } from '@smh/projects/regions';
import { guardExist } from '@smh/utils/guards';
import { componentFactory } from 'vue-tsx-support';

import { tsStoreMixin } from '@jtnews/shared';
import { LogoSvg } from '@jtnews/shared/logos';

import styles from './logo.styles.scss?module';

type ExternalEventsReference = {
  link: string;
  regions: Regions[];
  pages: string[];
};

type Link = {
  href: string;
  target: '_self' | '_blank';
};

type Logos = 'base' | 'ny' | 'award' | 'sup';

// переменные для выставления внешних ссылок на логотип
const EXTERNAL_EVENTS_REFERENCE: ExternalEventsReference[] = [
  {
    link: '/award',
    regions: [Regions.Nsk],
    pages: ['главная', 'main']
  }
];

const AWARD_REGIONS: Regions[] = [Regions.Nsk];
const NY_REGIONS: Regions[] = [];
const SUP_REGIONS: Regions[] = [];

export default componentFactory.mixin(tsStoreMixin).create({
  name: 'Logo',
  props: {
    hasLink: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    regionId(): Regions {
      return this.store.regionId;
    },
    isMobile(): boolean {
      return this.store.deviceInfo.isMobile && !this.store.deviceInfo.isTablet;
    },
    hasExternalReference(): boolean {
      return (
        EXTERNAL_EVENTS_REFERENCE.some(r => r.link !== '') &&
        EXTERNAL_EVENTS_REFERENCE.some(r => r.regions.includes(this.regionId)) &&
        EXTERNAL_EVENTS_REFERENCE.some(r => r.pages.includes(this.$route.name || ''))
      );
    },
    link(): Link {
      const eventLink = EXTERNAL_EVENTS_REFERENCE.find(r =>
        r.regions.includes(this.regionId)
      );

      return this.hasExternalReference && guardExist(eventLink)
        ? { href: eventLink.link, target: '_blank' }
        : { href: '/', target: '_self' };
    },
    type(): Logos {
      if (AWARD_REGIONS.includes(this.regionId)) {
        return 'award';
      }

      if (NY_REGIONS.includes(this.regionId)) {
        return 'ny';
      }

      if (SUP_REGIONS.includes(this.regionId)) {
        return 'sup';
      }

      return 'base';
    }
  },
  render() {
    const attributes = {
      attrs: this.$attrs,
      on: this.$listeners
    };

    const logo = (
      <LogoSvg type={this.type} regionId={this.regionId} isMobile={this.isMobile} />
    );

    return this.hasLink ? (
      <a
        class={[styles.logo, styles[this.type]]}
        href={this.link.href}
        target={this.link.target}
        data-test="logotype"
        {...attributes}
      >
        {logo}
      </a>
    ) : (
      <div class={[styles.logo, styles[this.type]]}>{logo}</div>
    );
  }
});
